<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body>
          <b-card-body>
            <b-row>
                  <!-- 
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Başlık</label>
                <b-form-input
                  id="basicInput"
                  placeholder="Başlık"
                  v-model="createCampaign.caption"
                />
              </b-col>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Açıklama</label>
                <b-form-input
                  id="basicInput"
                  placeholder="Açıklama"
                  v-model="createCampaign.description"
                />
              </b-col> -->
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                 <p>Görsel Boyutu 2060x760 olarak Hazırlanmalıdır.</p>
              </b-col>

               <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <label>Görsel</label>
                <b-form-file
                  v-model="file"
                  placeholder="Bir dosya seçin veya buraya bırakın"
                  drop-placeholder="Dosyayı buraya bırakın.."
                  accept=".jpg, .png, .jpeg"
                />
              </b-col>

              <b-col cols="12" md="3" class="mb-md-0 mb-2">
              </b-col>
             
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <div class="d-flex mt-2 justify-content-end">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="saveCampaign()"
                  >
                    <feather-icon icon="ArrowUpIcon" class="mr-50" />
                    <span class="align-middle">Kaydet</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-0">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="campaigns"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Kayıt bulunamadı."
          >
            <template #cell(coverImgUrl)="data">
              <div class="item-quantity d-flex justify-content">
                <b-img
                  height="120"
                  :src="data.item.coverImgUrl"
                  alt="data.item.product.caption')"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="item-quantity d-flex justify-content">
                <b-button
                  style="margin-top: 0x"
                  type="button"
                  variant="primary"
                  @click="deleteCampaign(data)"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span class="align-middle">Sil</span>
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCardText,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BFormFile,
  BImg,
  BFormSpinbutton,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, email } from "@validations";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import Cleave from "vue-cleave-component";
export default {
  components: {
    BRow,
    BImg,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormFile,
    BCard,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    Cleave,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BCardText,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      campaigns: [],
      tableColumns: [
        { key: "coverImgUrl", label: "Görsel" },     
        { key: "actions", label: "İşlemler" }
      ],
      show: false,
      showDiscountPanel: false,
      userDiscount: 0,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand"
      },
      selectedDealerId: 0,
      createCampaign: {
        caption: "",
        description: ""
      },
      datasuggest: [],
      filteredOptions: [],
      selectedId: 0,
      file: null
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/Product/GetCampaigns")
        .then(response => {
          this.campaigns = response.data.result;
          this.show = false;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    deleteCampaign(data) {
      this.show = true;
      console.log(data);
      this.$http
        .get(`/Product/DeleteCampaign?id=${data.item.id}`)
        .then(response => {
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    saveCampaign() {
      this.show = true;
      if (this.file === null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce dosya seçiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary"
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false
        });
        return;
      }

      this.$http
        .post(`/Product/AddCampaign`, this.createCampaign)
        .then(responseMain => {
          this.createCampaign = {
            caption: "",
            description: ""
          };
          this.selectedId = responseMain.data.result.id;
          let formData = new FormData();
          formData.append("file", this.file);
          this.$http
            .post(
              `Product/AddCampaignImage?campaignId=${this.selectedId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
            .then(response => {
              this.show = false;
              this.init();
              this.$swal({
                title: "İşlem başarılı!",
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                confirmButtonText: "Kapat",
                buttonsStyling: false
              });
              this.file = null;
            })
            .catch(error => {
              this.show = false;
              this.$swal({
                title: "Hata!",
                text: error.response.data.responseException.exceptionMessage,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                confirmButtonText: "Kapat",
                buttonsStyling: false
              });
              this.file = null;
            });
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
